import List from 'list.js';
import gsap from 'gsap';

function initFilter() {
	const listOptions = {
		valueNames: ['cat'],
		page: 12,
		pagination: {
			innerWindow: 1,
			outerWindow: 1,
			item: '<li><div class="page"></div></li>'
		},
		listClass: '-list'
	}

	let listTest = new List('blog', listOptions);

	$('.no-result').hide();

	$('.o-filter_tag').on('click', function () {
		$(this).toggleClass('-selected');

		gsap.to($('.o-preloader_spinner_wrap'), {
			autoAlpha: 1,
			duration: 0.3,
			ease: 'power2.in'
		});

		setTimeout(() => {
			gsap.to($('.o-preloader_spinner_wrap'), {
				autoAlpha: 0,
				duration: 0.3,
				ease: 'power2.out'
			});
		}, 600);
	});

	$('.pagination').on('click', function() {
		gsap.to($('.o-preloader_spinner_wrap'), {
			autoAlpha: 1,
			duration: 0.3,
			ease: 'power2.in'
		});

		setTimeout(() => {
			gsap.to($('.o-preloader_spinner_wrap'), {
				autoAlpha: 0,
				duration: 0.3,
				ease: 'power2.out'
			});
		}, 600);
	});

	var filterBtns = $('.o-filter_tag');

	filterBtns.on('click', function () {

		var validValues = [];

		filterBtns.each(function () {
			if ($(this).hasClass('-selected')) {
				validValues.push($(this).data('filter'));
			}
		});

		if (validValues.length === 0) {
			filterBtns.each(function () {
				validValues.push($(this).data('filter'));
			});
		}

			listTest.filter(function (item) {
				if (validValues.indexOf(item.values().cat) > -1) {
					return true;
				} else {
					return false;
				}
			});

	});

	listTest.on('updated', function hideShowNextPrevButtons(list) {
		if (list.matchingItems.length > 0) {
			$('.no-result').hide();
			$('.btn-prev, .btn-next').show();
		} else {
			$('.no-result').show();
			$('.btn-prev, .btn-next').hide();
		}
	});

	$('.pagination-nav').append('<div class="btn-next"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve"> <g> <rect x="0.1" y="9" width="18.3" height="2"/> <polygon points="12.1,17.8 10.7,16.4 17.1,10 10.7,3.6 12.1,2.2 19.9,10 	"/> </g> </svg></div>');
	$('.pagination-nav').prepend('<div class="btn-prev"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve"> <g> <rect x="1.5" y="9" width="18.3" height="2"/> <polygon points="7.9,2.2 9.3,3.6 2.9,10 9.3,16.4 7.9,17.8 0.1,10"/> </g> </svg></div>');

	$('.btn-next').on('click', function () {
		$('.pagination .active').next().find('.page').trigger('click');
	});
	
	$('.btn-prev').on('click', function () {
		$('.pagination .active').prev().find('.page').trigger('click');
	});
}

module.exports = {
	initFilter
}