// Gsap
import gsap from 'gsap';

import Swiper, { Navigation } from 'swiper';

gsap.config({
	nullTargetWarn: false
});

// Swiper
Swiper.use([Navigation]);

function emitSlider() {
	// Slider pourquoi émettre
	const sliderEmettre = new Swiper('.slider', {
		slidesPerView: 3,
		grabCursor: false,
		spaceBetween: 40,
		direction: 'horizontal',
		speed: 1000,
		observer: true,
		observeParents: true,
		parallax: true,
		breakpoints: {

			1: {
				slidesPerView: 1
			},

			600: {
				slidesPerView: 2
			},

			1350: {
				slidesPerView: 3
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
}

module.exports = {
	emitSlider
};
