import gsap from 'gsap';

const isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

function transitionFirstLoad() {
	gsap.from($('[data-load-container] section, footer'), {
		autoAlpha: 0,
		y: 125,
		duration: 0.9,
		delay: 0.45,
		ease: 'power2.out'
	});
}

function transitionLeave() {
	gsap.to($('[data-load-container] section, footer'), {
		autoAlpha: 0,
		xPercent: -100,
		duration: 0.9,
		ease: 'power2.in',
		onComplete: backToTopOnMobile
	});
	gsap.to($('.o-preloader_spinner_wrap'), {
		autoAlpha: 1,
		duration: 0.3,
		ease: 'power2.in'
	});

	function backToTopOnMobile() {
		if (isMobile) {
			$('html, body').scrollTop(0, 0);
		}
	}
}

function transitionEnter() {
	gsap.from($('[data-load-container] section, footer'), {
		autoAlpha: 0,
		xPercent: 100,
		duration: 0.9,
		ease: 'power2.out'
	});

	gsap.to($('.o-preloader_spinner_wrap'), {
		autoAlpha: 0,
		duration: 0.3,
		ease: 'power2.out'
	});
}

module.exports = {
	transitionFirstLoad,
	transitionLeave,
	transitionEnter
};
