import 'owl.carousel';

function initCarousel() {
	$('.c-carousel').owlCarousel({
		items: 1,
		nav: true,
		dots: false,
		loop: false,
		// autoplay: true,
		// autoplayTimeout: 5000,
		// autoplayHoverPause: 'true',
		smartSpeed: 500,
		// Classes
		refreshClass: 'c-carousel-refresh',
		loadingClass: 'c-carouel-loading',
		loadedClass: 'c-carousel-loaded',
		rtlClass: 'c-carousel-rtl',
		dragClass: 'c-caoursel-drag',
		grabClass: 'c-carousel-grab',
		stageClass: 'c-carousel-stage',
		stageOuterClass: 'c-carousel-stage-outer',
		navContainerClass: 'c-carousel-nav',
		navClass: ['c-carousel-prev', 'c-carousel-next'],
		dotClass: 'c-carousel-dot',
		dotsClass: 'c-carousel-dots',
		autoHeightClass: 'c-carousel-height',
		itemClass: 'c-carousel-item',
		// Nav Buttons
		navText: ['<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" xml:space="preserve"> <rect x="1.5" y="9" width="18.3" height="2"/> <polygon points="7.9,2.2 9.3,3.6 2.9,10 9.3,16.4 7.9,17.8 0.1,10 	"/> </svg>', '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" xml:space="preserve"> <rect x="0.1" y="9" width="18.3" height="2"/> <polygon points="12.1,17.8 10.7,16.4 17.1,10 10.7,3.6 12.1,2.2 19.9,10"/> </svg>'],
		responsive : {
			// breakpoint from 0 up
			700 : {
				items: 2,
			},
			1000 : {
				items: 3,
			}
		}
	});
}

module.exports = {
	initCarousel
};
