function initPopup() {
	$('.c-read-more_popup').each(function findPopupButtons() {
		const popupClass = $(this).data('popup');

		$(this).on('click', function openPopup() {
			$('.' + popupClass).addClass('-open');
		});
	});

	$('.o-button_popup').each(function findPopupButtons2() {
		const popupClass = $(this).data('popup');

		$(this).on('click', function openPopup2() {
			$('.' + popupClass).addClass('-open');
		});
	});

	$('.o-button_popup .-last').each(function findPopupButtons3() {
		const popupClass = $(this).data('popup');

		$(this).on('click', function openPopup3() {
			$('.' + popupClass).addClass('-open');
		});
	});

	$('.o-popup_close').on('click', function closePopup() {
		$('.o-popup').removeClass('-open');
	});
}

module.exports = {
	initPopup
};
