import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/dist/plugins/overscroll';
import gsap from 'gsap';
import {
	ScrollTrigger
} from 'gsap/ScrollTrigger';
import SoftScroll from './_softscroll';

// ---- Register GSAP plugins ----
gsap.registerPlugin(ScrollTrigger);

function initScroll() {
	let gsapScrollTrigger;
	const isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

	if (isMobile) {
		gsapScrollTrigger = $('body');
	} else {
		gsapScrollTrigger = $('#scroll-container');
		$('html').addClass('has-smooth-scrollbar');

		Scrollbar.use(OverscrollPlugin, SoftScroll);

		let scrollPositionX = 0;
		let scrollPositionY = 0;
		const bodyScrollBar = Scrollbar.init(document.querySelector('#scroll-container'), {
			plugins: {
				overscroll: {
					effect: 'glow',
					glowColor: 'transparent'
				}
			}
		});

		bodyScrollBar.addListener(({
			offset
		}) => {
			scrollPositionX = offset.x;
			scrollPositionY = offset.y;
		});

		bodyScrollBar.setPosition(0, 0);
		bodyScrollBar.track.xAxis.element.remove();

		ScrollTrigger.scrollerProxy('#scroll-container', {
			scrollTop(value) {
				if (arguments.length) {
					bodyScrollBar.scrollTop = value;
				}
				return bodyScrollBar.scrollTop;
			}
		});

		bodyScrollBar.addListener(ScrollTrigger.update);

		bodyScrollBar.addListener((status) => {
			if (status.offset.y >= 160) {
				$('.c-header_sticky').addClass('show');
			} else {
				$('.c-header_sticky').removeClass('show');
			}
		});
	}

	$('.-parallax').each(function parallax() {
		gsap.to($(this).find('.c-bg-img'), {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				scrub: true
			},
			y: $(this).height() / 4,
			ease: 'none'
		});
	});

	$('.-slide').each(function parallax() {
		gsap.fromTo($(this).find('.o-ratio'), {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				scrub: true
			},
			y: $(this).height() / 2,
			ease: 'none'
		}, {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				scrub: true
			},
			y: -$(this).height() / 2,
			ease: 'none'
		});
		//
		gsap.fromTo($(this).find('.-slide-content'), {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				scrub: true
			},
			y: $(this).height() / 2,
			ease: 'none'
		}, {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				scrub: true
			},
			y: -$(this).height() / 2,
			ease: 'none'
		});
	});
}

module.exports = {
	initScroll
};

// $('.o-button').on('click', () => {
// 	// scrollbar.scrollTo(0, top, 1000);
// 	scrollbar.scrollIntoView(document.querySelector('#about'), {
// 		offsetTop: 80
// 	});
// });

// Example to stick element inside scrollbar, but it's a bit laggy
// scrollbar.addListener((status) => {
// 	const offset = status.offset;
// 	console.log(offset.y + ' scroll offset');
// 	$('.-parallax').each(function () {

// 		console.log(Math.trunc($(this).offset().top));

// 		var elOffsetTop = Math.trunc($(this).offset().top);

// 		if (elOffsetTop < $(window).height() && elOffsetTop > -($(this).height())) {
// 			$(this).find('.c-bg-img').css({
// 				'transform': 'translate3d(0px, ' + (-(elOffsetTop / 8)) + 'px, 0px)'
// 			});
// 		}

// 	});
// });
