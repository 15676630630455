// Packages
import $ from 'jquery';
import gsap from 'gsap';

function initNavigation() {

	// Toggler
	$('.c-header_nav_toggler').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.c-mobile_nav').toggleClass('is-active');
	});

	// Search sticky
	$('.c-header_nav_search-button2').unbind('click').bind('click', (event) => {
		if (!$('.c-header_search').hasClass('is-active')) {
			$('.c-header_search .c-form_input').focus();
		}
		$('.c-header_search').toggleClass('is-active');
		$('.c-header_nav_search-button2').removeClass('is-active');
		
	});

	// Search principal
	$('.c-header_nav_search-button-principal').on('click', (event) => {
		if (!$('.c-header_search2').hasClass('is-active2')) {
			$('.c-header_search2 .c-form_input').focus();
		}
		$('.c-header_search2').toggleClass('is-active2');
		$('.c-header_nav_search-button-principal').removeClass('is-active2');
	});

	// Mini cart
	$('.c-header_nav_cart-button').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.o-mini-cart').toggleClass('is-active');
		$('.c-header_search').removeClass('is-active');
	});
	$('body').on('click', '.o-mini-cart .close', () => {
		//$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});

	gsap.set($('.c-nav .c-header_nav_subnav_list'), {
		height: 'auto'
	});
	gsap.set($('.c-nav .c-header_nav_subnav_list'), {
		height: 0
	});

	$('.c-nav .menu-item-has-children .c-header_nav_link').on('click', function toggleSubNav() {
		$(this).toggleClass('open');

		if ($(this).hasClass('open')) {

			$('.c-nav .menu-item-has-children .c-header_nav_link').not($(this)).removeClass('open');
			gsap.to($('.c-nav .menu-item-has-children .c-header_nav_link').not($(this)).find('.c-header_nav_subnav_list'), {
				height: 0,
				duration: 0.3
			});

			$(this).find('.c-header_nav_subnav_list').removeAttr('style');
			gsap.set($(this).find('.c-header_nav_subnav_list'), {
				height: 'auto'
			});
			gsap.from($(this).find('.c-header_nav_subnav_list'), {
				height: 0,
				duration: 0.3
			});
		} else {
			gsap.to($(this).find('.c-header_nav_subnav_list'), {
				height: 0,
				duration: 0.3
			});
		}
	});

	$('.c-header_toggler').on('click', function toggleNav() {
		$('.c-nav, .c-header_toggler').toggleClass('open');
	});

	$('.c-nav .c-nav_item:not(.menu-item-has-children) .c-header_nav_link, .c-header_logo, .c-nav .c-nav_item .c-header_nav_link .c-header_nav_subnav_list .c-header-nav_subnav_item .c-header_nav_subnav_link').on('click', function toggleNav() {
		$('.c-nav, .c-header_toggler').removeClass('open');
	});
}

module.exports = {
	initNavigation
};