import 'babel-polyfill';
import ModularLoad from 'modularload';
import gsap from 'gsap';
import Cookies from 'js-cookie';

gsap.config({
	nullTargetWarn: false
});

const sliders = require('./_sliders');
const navigation = require('./_navigation');
const carousel = require('./_carousel');
const filter = require('./_filter');
const popup = require('./_popup');
const magnet = require('./_magnet');
const pagetransition = require('./_transition');
const scroll = require('./_smoothScroll');

const load = new ModularLoad({
	enterDelay: 1200
});

load.on('loading', () => {
	pagetransition.transitionLeave();
});

load.on('loaded', () => {
	pagetransition.transitionEnter();
	carousel.initCarousel();
	filter.initFilter();
	popup.initPopup();
	magnet.initMagnet();
	scroll.initScroll();
	navigation.initNavigation();
	sliders.emitSlider();
	window.dispatchEvent(new Event('resize'));
	if ($('.quform').length) {
		const $script = $('#quform-js');
		$.ajax({
			url: $script.attr('src'),
			cache: true,
			dataType: 'script',
			success: function () {
				$script.trigger('load');
			}
		});
	}
});

const App = {
	// App.init
	init() {
		pagetransition.transitionFirstLoad($('[data-load-container]'));
		carousel.initCarousel();
		filter.initFilter();
		popup.initPopup();
		magnet.initMagnet();
		scroll.initScroll();
		navigation.initNavigation();
		sliders.emitSlider();
		console.log('%cDesign and development 👉 Cinetic', 'font-size:10px;font-weight: bold;color:#fff; background-color:#135cfd; padding:5px 10px;border-radius:200px;');

		gsap.to($('.o-preloader'), {
			yPercent: -101,
			duration: 0.9,
			delay: 0.3,
			ease: 'power2.out'
		});

		gsap.to($('.o-preloader_spinner_wrap'), {
			autoAlpha: 0,
			duration: 0.3,
			delay: 0.3,
			ease: 'power2.out'
		});

		setTimeout(() => {
			$('html').addClass('is-loaded is-ready');
			$('html').removeClass('is-loading');
		}, 300);

		if (Cookies.get('notice') === 'close') {
			$('.c-notice, .c-notice_bg').addClass('close');
		}

		gsap.from($('.c-notice:not(.close)'), {
			autoAlpha: 0,
			xPercent: 50,
			duration: 0.6,
			delay: 1.25,
			ease: 'power2.out',
			clearProps: 'all'
		});
	}
};

window.onload = () => {
	const $style = document.getElementById('theme-css');

	if ($style.isLoaded) {
		App.init();
	} else {
		$style.addEventListener('load', () => {
			App.init();
		});
	}
};

$('#notice-button').on('click', () => {
	Cookies.set('notice', 'close', {
		expires: 30
	});
	$('.c-notice, .c-notice_bg').addClass('close');
});
